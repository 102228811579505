import { useEffect,useState,useContext } from 'react';
import SmallPromoBox from "./smallPromo";
import AppContext from '../../../AppContext';
import { useAuth } from 'react-oidc-context';
import {uscall,getresourceurl} from '../../../us';


function PromoX1Box({areacode}) {
    const auth = useAuth();
    const [box,setBox]=useState(null);
    const [bannerimageurl,setBannerimageurl]=useState(null);
    const [titleimageurl,setTitleimageurl]=useState(null);
    const appcontext = useContext(AppContext);
    useEffect(() => {
	const cb=async function(){
		const r=await uscall(appcontext,auth,'ecommerce','boxpromoarea','getbox',null,{areacode:areacode});
		if(typeof(r['error'])!='undefined');
		else if(typeof(r['result'])!='undefined' && r.result)setBox(r.result);
	}
	if(box);else if(areacode)
		cb();
    },[box,areacode,auth,appcontext,appcontext.manifest.relations]);
    useEffect(() => {
	const cb=async function(){
		setBannerimageurl(await getresourceurl(appcontext,auth,box.banner_image));
	}
	if(box && box.banner_image && (!bannerimageurl))
		cb();
    },[box,bannerimageurl,auth,appcontext]);
    useEffect(() => {
	const cb=async function(){
		setTitleimageurl(await getresourceurl(appcontext,auth,box.title_image));
	}
	if(box && box.titleisimage && box.title_image && (!titleimageurl))
		cb();
    },[box,bannerimageurl,auth,appcontext]);

    return (
        <>
                <div className={`dash-box h-460 ${box?'':'placeholder-glow'}`}>
					{box && box.titleisimage && titleimageurl?(
	        		            <img src={titleimageurl} alt={box.title} style={{minHeight:'auto',margin:'0 auto'}} />
					):(
		                    <h1 className={`text-center mb-0 p-0 ${box?'':'placeholder'}`} style={{ width: '100%' }}>{box?box.title:''}</h1>)}
        		            <h5 className={`text-center mx-auto mb-0 ${box?'':'placeholder'}`}>{box?box.subtitle:''}</h5>
				    {box && box.isbanner?(<>
	        		            <img src={bannerimageurl?bannerimageurl:`${process.env.PUBLIC_URL}/placeholder-foto.png`} alt={box.title} />
	        		            <a href={box.banner_url} className="gradient-button">{box.banner_cta}</a>
						</>):(box?(<>
                    				<div className="row small-promo-container w-100 g-0">
                    					<SmallPromoBox url={box.box1_url} cta={box.box1_cta} color={box.box1_color} />
                        				<SmallPromoBox url={box.box2_url} cta={box.box2_cta} color={box.box2_color} />
                        				<SmallPromoBox url={box.box3_url} cta={box.box3_cta} color={box.box3_color} />
                        				<SmallPromoBox url={box.box4_url} cta={box.box4_cta} color={box.box4_color} />
                        				<SmallPromoBox url={box.box5_url} cta={box.box5_cta} color={box.box5_color} />
                        				<SmallPromoBox url={box.box6_url} cta={box.box6_cta} color={box.box6_color} />

                        				<SmallPromoBox url={box.box7_url} cta={box.box7_cta} color={box.box7_color} />
                        				<SmallPromoBox url={box.box8_url} cta={box.box8_cta} color={box.box8_color} />
                    				</div>

						</>):(<></>))}
                </div>
        </>
    )
}

export default PromoX1Box;
